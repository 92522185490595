##############
class @Tracker
##############
  #https://gist.github.com/erpe/8586565

  @isLoaded: false

  ##################
  @initialize: () ->
  ##################
    @load()

  #########
  @load: ->
  #########
    window._paq = undefined
    if !@canTrack()
        return

    theSiteId = Tracker.siteId()
    if not theSiteId
      return

    window._paq = []

    @setUserId(USER_NAME)

    @isLoaded = true

    # Piwik Analytics depends on a global _paq array. window is the global scope.
    window._paq.push(['setTrackerUrl', Tracker.trackerUrl() + '/piwik.php'])
    window._paq.push(['setSiteId', theSiteId])
    window._paq.push(['enableLinkTracking'])

    # Create a script element and insert it in the DOM
    pa = document.createElement("script")
    pa.type = "text/javascript"
    pa.defer = true
    pa.async = true
    pa.src =  Tracker.trackerUrl() + '/piwik.js'
    firstScript = document.getElementsByTagName("script")[0]
    firstScript.parentNode.insertBefore pa, firstScript

    # If Turbolinks is supported, set up a callback to track pageviews on page:change.
    # If it isn't supported, just track the pageview now.
    if typeof Turbolinks isnt 'undefined' and Turbolinks.supported
      document.addEventListener "page:change", (->
        Tracker.trackPageView()
      ), true
    else
      Tracker.trackPageView()

  #############
  @canTrack: ->
  #############
    return Ext.util.Cookies.get('ignore_matomo_logging') != 'true'

  ########################
  @trackPageView: (url) ->
  ########################
    if (!@isLoaded)
        @load()

    if not window._paq then return
    if url
      window._paq.push(["trackPageView", url])
    else
      window._paq.push(["trackPageView"])

  #############################
  @trackGoal: (id, price=0 ) ->
  #############################
    if (!@isLoaded)
        @load()

    if not window._paq then return
    window._paq.push(['trackGoal', id, price])

  ############################################################
  @trackEvent: (inCategory, inAction, inName="", inValue=0) ->
  ############################################################
    if (!@isLoaded)
        @load()

    if not window._paq then return
    window._paq.push(['trackEvent', inCategory, inAction, inName, inValue])

  ###################
  @setUserId: (id) ->
  ###################
    if not window._paq then return
    window._paq.push(['setUserId', id])

  #################################
  @documentDomainIncludes: (str) ->
  #################################
    document.domain.indexOf(str) isnt -1

  ###########
  @siteId: ->
  ###########
    if (window.location.href.indexOf("feature.top-modular") >= 0) or (window.location.href.indexOf("test.top-modular") >= 0)
      return false

    if (window.location.href.indexOf("topnext.dev2") >= 0) or (window.location.href.indexOf("dev.top-modular") >= 0)
      return "1"

    if (window.location.href.indexOf("topnext.prod") >= 0) or (window.location.href.indexOf("top-modular") >= 0)
      return "2"

    return false

  ###############
  @trackerUrl: ->
  ###############
    return document.location.protocol + "//analytics-services.comsulting.net"
